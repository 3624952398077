import React, { ReactElement } from 'react';
import {
  BrowserRouter as Router,
} from 'react-router-dom';
import Routes from './router/routes';
import Navbar from './components/common/Navbar/Navbar.component';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

const App = (): ReactElement => {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes />
      </Router>
      <ToastContainer position="bottom-right" />
    </div>
  );
}

export default App;
