import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { initial } from './initial';

import reducers from './reducers/Reducers';

const persistConfig = {
  key: 'hugetalents-crawler',
  storage,
  blacklist: ['Candidate']
};

const persistedReducer = persistReducer(persistConfig, reducers);

// eslint-disable-next-line import/no-anonymous-default-export
export default (): any => {
  const store = createStore(
      persistedReducer,
      initial,
      composeWithDevTools(applyMiddleware(thunk)),
  );
  // @ts-ignore
  const persistor = persistStore(store);
  return { store, persistor };
};
