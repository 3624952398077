import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'react-bootstrap';
import { Jumbotron } from './Login.container.styles';
import LoginForm from '../../components/forms/Login/Login.form';

const LoginContainer = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Container>
      <Jumbotron className="p-5 mb-4 bg-light rounded-3">
        <Row>
          <Col
            xs={12}
            md={6}
            lg={4}>
            <h2 className="mb-4">{t('Zaloguj się do panelu')}</h2>
            <LoginForm />
          </Col>
        </Row>
      </Jumbotron>
    </Container>
  );
}

export default LoginContainer;
