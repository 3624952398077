import React  from 'react';
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import routes from '../../../router/config';
import { AppState } from '../../../store/reducers/Reducers.type';

type PrivateRoutesComponentType = {
  element: any,
  isAuthorized?: boolean,
};

const PrivateRoute = ({
  element,
  isAuthorized
}: PrivateRoutesComponentType) => (isAuthorized ? element : <Navigate to={routes.LOGIN} />);

const mapStateToProps = (state: AppState) => ({
  isAuthorized: state.Authorization.state,
});

export default connect(
    mapStateToProps,
    null,
)(PrivateRoute);
