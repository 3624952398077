import styled from 'styled-components';
import { COLORS } from '../../../theme/config';

export const NavbarWrapper = styled.div`
  .bg-dark {
    background: ${COLORS.BG_DARK}
  }
  
  .clear-button {
    margin-left: 1rem;
  }
  
  a {
    text-decoration: none;
  }
`;

export const NavbarBrand = styled.span`
    margin-left: 1rem;
`;

export const StyledLogged = styled.div`
  text-align: right;
  color: #FFF;
  
  > * {
    display: block;
  }
  
  span {
    font-size: 75%;
  }
  
  b {
    font-size: 1rem;
    margin-top: -.25rem;
  }
`;

export const StyledUserSection = styled.div`
  display: flex;
`;
