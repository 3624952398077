import { StoreTypes } from '../../Store.types';
import { logger, API } from '../../../utils';
import { LoginDataType } from './Authorization.type';
import { Dispatch } from 'redux';
import { toast } from 'react-toastify';
import { FormikValues } from 'formik';

const Login = (data: LoginDataType) => async (dispatch: Dispatch): Promise<void> => {
  if (data.username !== 'user' && data.password !== 'password') {
    toast.error('Error, spróbuj user password');
    return;
  }

  dispatch({
    type: StoreTypes.AUTHORIZATION.LOGIN,
    data: {
      username: data.username,
    },
    error: null,
  });

  dispatch({
    type: StoreTypes.AUTHORIZATION.STATUS,
    data: true,
  });

  return; // @TODO ^^^ Remove

  try {
    const response = await API.post('/auth/login', data);
    if (response.status === 200) {
      const { accessToken, refreshToken } = response.data;

      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);

      dispatch({
        type: StoreTypes.AUTHORIZATION.LOGIN,
        data: {
          username: data.username,
        },
        error: null,
      });

      dispatch({
        type: StoreTypes.AUTHORIZATION.STATUS,
        data: true,
      });
    }
  } catch (e) {
    // @ts-ignore
    toast.error(e.response.data.error)
    logger.error(e);
  }
};

const Logout = () => async (dispatch: Dispatch): Promise<void> => {
  try {
    dispatch({
      type: StoreTypes.AUTHORIZATION.LOGOUT,
    });

    dispatch({
      type: StoreTypes.AUTHORIZATION.STATUS,
      data: false,
    });

    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  } catch (e) {
    logger.error(e);
  }
};

const ResetPassword = (data: FormikValues) => async (): Promise<boolean | void> => {
  try {
    await API.patch('/user/password/reset', data);
    return true;
  } catch (e) {
    // @ts-ignore
    toast.error(e.response.data.error)
    logger.error(e);
  }
};

export {
  Login,
  Logout,
  ResetPassword,
};
