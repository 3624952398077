import React from 'react';
import { AppState } from '../../store/reducers/Reducers.type';
import { connect } from 'react-redux';

type DashboardType = StateProps & DispatchProps;

const DashboardContainer = (params: DashboardType): JSX.Element => {
  return (
    <div>
      Test
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({

});

const mapDispatchToProps = (dispatch: any) => ({

});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DashboardContainer);
