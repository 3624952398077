import React, { useState } from 'react';
import {
  Container,
  Nav,
  Navbar,
} from 'react-bootstrap';
import Logo from './assets/logo.png';
import { NavbarWrapper } from './Navbar.styles';
import { connect } from 'react-redux';
import { AppState } from '../../../store/reducers/Reducers.type';
import { useTranslation } from 'react-i18next';
import { StyledUserSection, StyledLogged } from './Navbar.styles'
import { Dropdown } from 'react-bootstrap';
import { Logout } from '../../../store/actions/Authorization/Authorization.action';
import routes from '../../../router/config';
import { Link } from 'react-router-dom';
import PasswordResetModal from '../../../containers/PasswordResetModal/PasswordResetModal.container';

type NavbarType = StateProps & DispatchProps;

const NavbarComponent = ({
  isAuthorized,
  user,
  Logout
}: NavbarType): JSX.Element => {
  const { t } = useTranslation();
  const [passwordResetModalVisible, setPasswordResetModalVisible] = useState<boolean>(false);

  const handleLogOut = () => {
    Logout();
  }

  return (
    <NavbarWrapper>
      <Navbar bg="dark" variant="dark">
        <Container>
          <Link to={routes.DASHBOARD}>
            <Navbar.Brand>
              <img src={Logo} alt="Huge Talents" height="60px" />
            </Navbar.Brand>
          </Link>
          {
            isAuthorized && (
              <Nav className="me-auto">
                <Link to={routes.DASHBOARD}>
                  <span className="nav-link">{t('Wyszukiwarka firm')}</span>
                </Link>
                <Link to={routes.DASHBOARD}>
                  <span className="nav-link">{t('Kandydaci')}</span>
                </Link>
              </Nav>
            )
          }

          {
            isAuthorized && (
              <StyledUserSection>
                <StyledLogged>
                  <span>{t('Zalogowany jako')}</span>
                  <b>{user}</b>
                </StyledLogged>

                <Dropdown className="mx-2">
                  <Dropdown.Toggle id="dropdown-autoclose-true" variant="outline-light" />

                  <Dropdown.Menu>
                    <Dropdown.Item href="#" onClick={() => setPasswordResetModalVisible(true)}>{t('Zmień hasło')}</Dropdown.Item>
                    <Link to={routes.DASHBOARD}><span className="dropdown-item">{t('Statystyki')}</span></Link>
                    <Dropdown.Item href="#" onClick={handleLogOut}>{t('Wyloguj')}</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </StyledUserSection>
            )
          }
        </Container>
      </Navbar>

      <PasswordResetModal
        onHide={() => setPasswordResetModalVisible(false)}
        isVisible={passwordResetModalVisible}
      />
    </NavbarWrapper>
  )
};

const mapStateToProps = (state: AppState) => ({
  isAuthorized: state.Authorization.state,
  user: state.Authorization.data?.username
});

const mapDispatchToProps = (dispatch: any) => ({
  Logout: () => dispatch(Logout()),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NavbarComponent);
