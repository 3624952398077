import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationPL from '../translations/pl.json';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      pl: {
        translation: translationPL,
      },
    },
    lng: 'pl',
    debug: false,
    keySeparator: false,

    react: {
      useSuspense: false,
      transSupportBasicHtmlNodes: true,
    },

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
