import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik, Form as FormikForm } from 'formik';
import * as Yup from 'yup';
import {
  FormGroup,
  Button,
  Form,
  FloatingLabel,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  StyledLoginForm,
} from './Login.form.style';
import { useNavigate } from 'react-router-dom';
import { Login } from '../../../store/actions/Authorization/Authorization.action';
import { AppState } from '../../../store/reducers/Reducers.type';
import { LoginDataType } from '../../../store/actions/Authorization/Authorization.type';
import config from '../../../router/config';

type LoginFormType = StateProps & DispatchProps;

const LoginForm = ({
  Login,
  isAuthorized,
}: LoginFormType) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const initialValues = {
    username: '',
    password: '',
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string().required(t<string>('Required')),
    password: Yup.string().required(t<string>('Required')),
  });

  useEffect(() => {
    if (isAuthorized) {
      navigate(config.DASHBOARD)
    }
  }, [isAuthorized, navigate])

  const onSubmit = async (values: LoginDataType): Promise<void> => {
    await Login(values);
  }

  return (
    <StyledLoginForm>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({
          handleChange, handleBlur, values, errors, touched, isSubmitting,
        }) => (
          <FormikForm>
            <FormGroup className="mb-3">
              <FloatingLabel
                controlId="username"
                label={t('Nazwa użytkownika')}
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  name="username"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.username}
                  isInvalid={touched.username && !!errors.username}
                />
              </FloatingLabel>

              <Form.Control.Feedback type="invalid">
                {errors.username}
              </Form.Control.Feedback>
            </FormGroup>

            <FormGroup className="mb-3">
              <FloatingLabel
                controlId="password"
                label={t('Hasło')}
                className="mb-3"
              >
                <Form.Control
                  type="password"
                  name="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  isInvalid={touched.password && !!errors.password}
                />
              </FloatingLabel>

              <Form.Control.Feedback type="invalid">
                {errors.password}
              </Form.Control.Feedback>
            </FormGroup>

            <Button
              variant="outline-dark"
              type="submit"
              className="me-2"
              disabled={isSubmitting}
            >
              Zaloguj
            </Button>
          </FormikForm>
        )}
      </Formik>
    </StyledLoginForm>
  );
};

const mapStateToProps = (state: AppState) => ({
  isAuthorized: state.Authorization.state
});

const mapDispatchToProps = (dispatch: any) => ({
  Login: (data: LoginDataType) => dispatch(Login(data)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginForm);
