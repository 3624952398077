import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import PasswordResetForm from '../../components/forms/PasswordReset/PasswordReset.form';

type ProjectModalProps = {
  isVisible: boolean;
  onHide: () => void;
};

const PasswordResetModalContainer = ({
  isVisible,
  onHide,
}: ProjectModalProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Modal
      show={isVisible}
      onHide={onHide}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{t('Zmien hasło')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <PasswordResetForm
          onHide={onHide}
        />
      </Modal.Body>
    </Modal>
  );
}

export default PasswordResetModalContainer;
