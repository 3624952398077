import { StoreTypes } from '../../Store.types';
import { ActionType } from '../Reducers.type';

const AuthorizationReducer = (state = {}, action: ActionType): any => {
  switch (action.type) {
    case StoreTypes.AUTHORIZATION.LOGIN:
      return { ...state, data: action.data };
    case StoreTypes.AUTHORIZATION.LOGOUT:
      return { ...state, data: null };
    case StoreTypes.AUTHORIZATION.STATUS:
      return { ...state, state: action.data };
    default:
      return state;
  }
};
export default AuthorizationReducer;
