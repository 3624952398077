import React from 'react';
import { connect } from 'react-redux';
import { Formik, Form as FormikForm, FormikValues } from 'formik';
import * as Yup from 'yup';
import {
  FormGroup,
  Button,
  Form,
  FloatingLabel,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { StyledResetPasswordForm } from './PasswordReset.form.style';
import { ResetPassword } from '../../../store/actions/Authorization/Authorization.action';
import { AppState } from '../../../store/reducers/Reducers.type';
import { toast } from 'react-toastify';
import { logger } from '../../../utils';

type LoginFormType = StateProps & DispatchProps & {
  onHide: () => void;
};

const PasswordResetForm = ({
  ResetPassword,
  onHide,
}: LoginFormType) => {
  const { t } = useTranslation();

  const initialValues = {
    password: '',
    newPassword: '',
    repeatNewPassword: '',
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string().required(t<string>('Required')),
    newPassword: Yup.string().required(t<string>('Required')),
    repeatNewPassword: Yup.string().required(t<string>('Required')),
  });

  // Well, maybe in future refactor it into dispatch
  // For this moment that is overkill
  const onSubmit = async (values: FormikValues): Promise<void> => {
    if(values.newPassword !== values.repeatNewPassword) {
      toast.error(t('Podane hasła się różnią'))
    } else {
      try {
        const response = await ResetPassword(values);
        if(response) {
          toast.success(t('Hasło zostało zmienione'));
          onHide();
        }

      } catch(e) {
        logger.error(e);
      }
    }
  }

  return (
    <StyledResetPasswordForm>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({
          handleChange,
          handleBlur,
          values,
          errors,
          touched,
          isSubmitting,
        }) => (
          <FormikForm>
            <FormGroup className="mb-3">
              <FloatingLabel
                controlId="password"
                label={t('Aktualne hasło')}
                className="mb-3"
              >
                <Form.Control
                  type="password"
                  name="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  isInvalid={touched.password && !!errors.password}
                />
              </FloatingLabel>

              <Form.Control.Feedback type="invalid">
                {errors.password}
              </Form.Control.Feedback>
            </FormGroup>

            <FormGroup className="mb-3">
              <FloatingLabel
                controlId="newPassword"
                label={t('Nowe hasło')}
                className="mb-3"
              >
                <Form.Control
                  type="password"
                  name="newPassword"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.newPassword}
                  isInvalid={touched.newPassword && !!errors.newPassword}
                />
              </FloatingLabel>

              <Form.Control.Feedback type="invalid">
                {errors.newPassword}
              </Form.Control.Feedback>
            </FormGroup>

            <FormGroup className="mb-3">
              <FloatingLabel
                controlId="repeatNewPassword"
                label={t('Powtórz nowe hasło')}
                className="mb-3"
              >
                <Form.Control
                  type="password"
                  name="repeatNewPassword"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.repeatNewPassword}
                  isInvalid={touched.repeatNewPassword && !!errors.repeatNewPassword}
                />
              </FloatingLabel>

              <Form.Control.Feedback type="invalid">
                {errors.repeatNewPassword}
              </Form.Control.Feedback>
            </FormGroup>

            <Button
              variant="primary"
              type="submit"
              className="ms-auto"
              disabled={isSubmitting}
            >
              {t('Zresetuj')}
            </Button>
          </FormikForm>
        )}
      </Formik>
    </StyledResetPasswordForm>
  );
};

const mapStateToProps = (state: AppState) => ({
  isAuthorized: state.Authorization.state
});

const mapDispatchToProps = (dispatch: any) => ({
  ResetPassword: (data: FormikValues) => dispatch(ResetPassword(data)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PasswordResetForm);
