import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const getAxiosConfig = (config = {}, params = {}, requestHeaders = {}) => {
  const accessToken = window.localStorage.getItem('accessToken');

  const headers = {
    ...requestHeaders,
    ...(accessToken ? {'x-access-token': `${accessToken}`} : {})
  }

  return {
    ...config,
    headers,
    params,
  }
};

const API = {
  get: async (
    url: string,
    params?: any,
  ): Promise<AxiosResponse> => axiosInstance.get(url, getAxiosConfig({}, params)),

  post: async (
    url: string,
    data?: any,
    params?: any,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse> => axiosInstance.post(url, data, getAxiosConfig(config, params, config?.headers)),

  patch: async (
    url: string,
    data?: any,
    params?: any,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse> => axiosInstance.patch(url, data, getAxiosConfig(config, params, config?.headers)),
};

export {
  API,
};
