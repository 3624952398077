import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { connect } from 'react-redux';
import PrivateRoute from '../components/common/PrivateRoute/PrivateRoute.component';
import routes from './config';
import { AppState } from '../store/reducers/Reducers.type';

import LoginView from '../containers/Login/Login.container';
import DashboardView from '../containers/Dashboard/Dashboard.container';

type RoutesComponentType = StateProps;

const RoutesComponent = ({
    isAuthorized,
}: RoutesComponentType) => (
    <Routes>
        <Route path={routes.BASE_PATH} element={isAuthorized ? <Navigate to={routes.DASHBOARD} /> : <Navigate to={routes.LOGIN} />} />
        <Route path={routes.LOGIN} element={<LoginView />} />
        <Route path={routes.DASHBOARD} element={<PrivateRoute element={<DashboardView />} />} />
        <Route path="*" element={<Navigate to={routes.NOT_FOUND} />} />
    </Routes>
);

RoutesComponent.defaultProps = {
    isAuthorized: false,
}

const mapStateToProps = (state: AppState) => ({
    isAuthorized: state.Authorization.state,
});

type StateProps = ReturnType<typeof mapStateToProps>;
export default connect(
    mapStateToProps,
    null,
)(RoutesComponent);
